import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import navigations from 'styles/Navigation.module.scss';
import Menu from './menu';
import PhotonAnimation from '../animation/photonAnimation';

interface INavbar {
	dbUser?: any | null;
}

export default function Nav({ dbUser }: INavbar) {
	const [expanded, setExpanded] = useState(false);

	const toggleMobileMenu = () => {
		setExpanded(!expanded);
	};

	return (
		<nav className={navigations.mainnav} id='nav'>
			<div className={navigations.desktop}>
				<div className='container'>
					<div className='d-flex justify-content-between align-items-center'>
						<Link href='/' className='position-relative'>
							<Image
								src='/logo/altreal-logo-desktop.png'
								alt='alt.real logo desktop'
								className={navigations.navLogo}
								fill
								sizes='(max-width: 992px) 100vw, (max-width: 1200px) 50vw, 33vw'
							/>
						</Link>
						<Menu isMobile={false} expanded={expanded} dbUser={dbUser}></Menu>
					</div>
				</div>
			</div>
			<div className={navigations.mobile}>
				<div className='container'>
					<div className='d-flex justify-content-between align-items-center'>
						<Link href='/'>
							<Image
								src='/logo/altreal-logo-mobile.png'
								alt='alt.real logo mobile'
								className={navigations.navLogo}
								width={88}
								height={64}
							/>
						</Link>
						<div
							className={navigations.mobileMenuToggle}
							onClick={() => toggleMobileMenu()}
						>
							<label
								id='label-mobile-menu-toggle-hidden'
								htmlFor='mobile-menu-toggle-hidden'
								className='d-none'
							></label>
							<input
								type='checkbox'
								id='mobile-menu-toggle-hidden'
								aria-labelledby='label-mobile-menu-toggle-hidden'
								aria-label='mobile menu toggle'
							/>
							<span></span>
							<span></span>
							<span></span>
						</div>
					</div>
				</div>
				<div className={navigations.mobileMenuContainer}>
					<Menu
						isMobile={true}
						expanded={expanded}
						clicked={() => setExpanded(false)}
						dbUser={dbUser}
					></Menu>
					<PhotonAnimation angle={45}></PhotonAnimation>
				</div>
			</div>
		</nav>
	);
}
