import pino from "pino";

const logger = pino({
	level: process.env.NEXT_PUBLIC_APP_LOG_LEVEL
		? process.env.NEXT_PUBLIC_APP_LOG_LEVEL.toLowerCase()
		: "info",
	formatters: {
		level: (label: string) => ({ level: label.toUpperCase() }),
	},
	timestamp: pino.stdTimeFunctions.epochTime,
	browser: { asObject: true },
});

logger.info("Logger initialized with level:", logger.level);

export default logger;
