import contentfulClient from 'contentful/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import cards from 'styles/ui/CardComponents.module.scss';
import Nav from 'components/navigation/navigation';
import blogs from 'styles/pages/Blog.module.scss';
import Footer from 'components/footer/footer';
import Image from 'next/image';
import GenericCarousel from 'components/ui/modules/GenericCarousel';
import { ENV_VARS } from 'helpers/constants';
import { GetStaticPropsContext } from 'next';
// @ts-ignore
import ReactStarsRating from 'react-awesome-stars-rating';
import ratings from 'styles/ui/Rating.module.scss';
import {
	getPostBody,
	getPostDate,
	getPostImgUrls,
	getPostRating,
	getPostSummary,
	getPostTags,
	getPostTitle,
} from 'helpers/contentfulHelpers';
import TagsComponent from 'components/ui/modules/TagsComponent';
import CustomHeader from 'components/customHeader';
import StudyStructuredData from 'data/structuredData/caseStudyStructuredData';

export async function getStaticPaths() {
	const locales = ENV_VARS.SUPPORTED_LOCALES;
	const contentTypeId = 'caseStudy';

	// Fetch entries for all supported locales
	const responses = await Promise.all(
		locales.map(async (locale) => {
			try {
				const response = await contentfulClient.getEntries({
					content_type: contentTypeId,
					locale,
				});
				return response.items;
			} catch (error) {
				console.error(`Error fetching entries for ${locale}:`, error);
				return [];
			}
		})
	);

	// Flatten the array of responses
	const posts = responses.flat();

	// Extract slugs for each post
	const metaData = posts.map((post) => ({
		slug: post.fields.slug?.toString(),
		locale: post.sys?.locale ?? ENV_VARS.DEFAULT_LOCALE,
	}));

	// Generate paths for all locales
	const paths = metaData.map((page) => ({
		params: { id: page.slug },
		locale: page.locale,
	}));

	return {
		paths,
		fallback: 'blocking', // can also be true or 'blocking'
	};
}

export async function getStaticProps(context: GetStaticPropsContext) {
	const id = String(context?.params?.id);
	const locale = context.locale ?? ENV_VARS.DEFAULT_LOCALE;
	try {
		const response = await contentfulClient.getEntries({
			content_type: 'caseStudy',
			'fields.slug': id,
			locale: locale,
		});

		if (response.items?.length < 1) {
			return { notFound: true };
		}

		const post = response.items[0];
		if (!post) {
			return { notFound: true };
		}

		return {
			props: {
				post: post,
				locale: locale,
			},
			revalidate: 10, // In seconds
		};
	} catch (error) {
		return { notFound: true };
	}
}

export default function CaseStudy({ post, locale }: any) {
	const postId = post.sys.id;
	const title = getPostTitle(post);
	const postImgUrls = getPostImgUrls(post);
	const postDate = getPostDate(post, locale);
	const tags = getPostTags(post);
	const rating = getPostRating(post);
	const body = getPostBody(post);
	const summary = getPostSummary(post);

	return (
		<div>
			<CustomHeader
				title={title}
				description={summary}
				keywords={tags}
				img={postImgUrls[0]}
				locale={locale}
				url={`${ENV_VARS.ALTREAL_DOMAIN}/case-studies/study/${postId}`}
			></CustomHeader>
			<div className={blogs.container}>
				<Nav></Nav>
				<article className={`${blogs.blog} container`}>
					<div className='row align-items-center'>
						<div className='col-lg-6 col-xl-6 col-12'>
							<div
								className={`${cards.estimatorCard} card p-0`}
								style={{ overflow: 'hidden' }}
							>
								{postImgUrls.length > 1 ? (
									<GenericCarousel
										forcedMaxGroupSize={1}
										carouselImgs={postImgUrls.map((x: string) => {
											return { path: x, alt: title };
										})}
										customCss={{ width: '100%' }}
									></GenericCarousel>
								) : (
									<Image
										className='position-relative'
										fill
										src={postImgUrls[0]}
										alt={title}
										style={{ objectFit: 'cover' }}
										placeholder='blur'
										blurDataURL='/images/loading-image-placeholder.svg'
									></Image>
								)}
							</div>
						</div>
						<section className='col-lg-6 col-xl-6 col-12'>
							<div className='m-3 m-md-4 m-lg-5 me-0'>
								<div className=''>
									<h1>{title}</h1>
									<div className='text-muted'>{postDate}</div>
									<div>
										<TagsComponent tags={tags}></TagsComponent>
									</div>
									<div className={`${ratings.ratingStars} ${ratings.lg} mt-3`}>
										<ReactStarsRating
											value={rating}
											isHalf={true}
											isEdit={false}
											id={`${postId}StarRating`}
											primaryColor={'#24ff00'}
											secondaryColor={'#eff0f5'}
											starGap={5}
										></ReactStarsRating>
									</div>
								</div>
							</div>
						</section>
					</div>
					<section
						className={`${blogs.content} ${blogs.standardBorder} mt-3 mb-3 mt-lg-5 mb-lg-5`}
					>
						<p className={`${blogs.summary}`}>
							<span className='text-black'>TLDR: </span>
							<span>{summary}</span>
						</p>
					</section>
					<section className={`${blogs.content} mb-3 mb-lg-5 pt-3`}>
						{body}
					</section>
					<StudyStructuredData
						post={post}
						locale={locale}
					></StudyStructuredData>
				</article>
				<Footer></Footer>
			</div>
		</div>
	);
}
