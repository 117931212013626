import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { FormattedMessage, useIntl } from 'react-intl';
import genericCarousel from 'styles/GenericCarousel.module.scss';
import Image from 'next/image';

import { CAROUSEL_OPTIONS } from 'helpers/featuredInSlidesConfigurations';

type CarouselImage = {
	href?: string;
	path: string;
	alt: string;
};

type ImageGroup = CarouselImage[][];

interface ICarouselControlButton {
	enabled: boolean;
	onClick: () => void;
}

export function PrevButton({ enabled, onClick }: ICarouselControlButton) {
	const intl = useIntl();
	return (
		<button
			className={`${genericCarousel.embla__button} ${genericCarousel.embla__button__prev}`}
			onClick={onClick}
			disabled={!enabled}
			aria-label={intl.formatMessage({
				id: 'component.controls.prev',
				defaultMessage: 'Previous',
			})}
		>
			<svg
				className={genericCarousel.embla__button__svg}
				viewBox='137.718 -1.001 366.563 644'
			>
				<path d='M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z' />
			</svg>
		</button>
	);
}

export function NextButton({ enabled, onClick }: ICarouselControlButton) {
	const intl = useIntl();
	return (
		<button
			className={`${genericCarousel.embla__button} ${genericCarousel.embla__button__next}`}
			onClick={onClick}
			disabled={!enabled}
			aria-label={intl.formatMessage({
				id: 'component.controls.next',
				defaultMessage: 'Next',
			})}
		>
			<svg
				className={genericCarousel.embla__button__svg}
				viewBox='0 0 238.003 238.003'
			>
				<path d='M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z' />
			</svg>
		</button>
	);
}

interface IGenericCarousel {
	forcedMaxGroupSize?: number;
	carouselImgs: CarouselImage[];
	showNavigationControls?: boolean;
	title?: string;
	customCss?: CSSProperties;
}

export default function GenericCarousel({
	forcedMaxGroupSize,
	carouselImgs,
	showNavigationControls = true,
	title,
	customCss,
}: IGenericCarousel) {
	const autoplayOptions = {
		delay: 3000,
		rootNode: (emblaRoot: { parentElement: any }) => emblaRoot.parentElement,
	};

	const [emblaRefCarousel, emblaApiCarousel] = useEmblaCarousel(
		CAROUSEL_OPTIONS,
		[Autoplay(autoplayOptions)]
	);

	const scrollPrev = useCallback(
		() => emblaApiCarousel && emblaApiCarousel.scrollPrev(),
		[emblaApiCarousel]
	);
	const scrollNext = useCallback(
		() => emblaApiCarousel && emblaApiCarousel.scrollNext(),
		[emblaApiCarousel]
	);

	const [groupedImages, setGroupedImages] = useState<ImageGroup>([]);
	const [groupSize, setGroupSize] = useState<number>(3);

	const initCarouselImages = (carouselImgs: CarouselImage[]) => {
		const groups: ImageGroup = carouselImgs.reduce(
			(groupArray: ImageGroup, item, index) => {
				const groupIndex = Math.floor(index / groupSize);

				if (!groupArray[groupIndex]) {
					groupArray[groupIndex] = [];
				}

				groupArray[groupIndex].push(item);

				return groupArray;
			},
			[]
		);

		setGroupedImages(groups);
	};

	useEffect(() => {
		initCarouselImages(carouselImgs);

		const handleResize = () => {
			if (forcedMaxGroupSize) {
				setGroupSize(forcedMaxGroupSize);
			} else {
				if (window.innerWidth >= 992) setGroupSize(3);
				else if (window.innerWidth < 992 && window.innerWidth >= 768)
					setGroupSize(2);
				else if (window.innerWidth < 768 && window.innerWidth >= 576)
					setGroupSize(1);
				else setGroupSize(1);
			}
		};

		window.addEventListener('resize', handleResize);

		// Call handleResize once on component mount to set initial value
		handleResize();

		// Clean up event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		initCarouselImages(carouselImgs);
	}, [carouselImgs, groupSize]);

	// fix the carousel init after adding slides
	useEffect(() => {
		emblaApiCarousel?.reInit(CAROUSEL_OPTIONS);
	}, [groupedImages]);

	return (
		<div className={`${genericCarousel.genericCarousel}`}>
			{title && (
				<div className={`container mt-4 mb-4`}>
					<h2
						className={`col-12 d-flex align-items-center justify-content-center pt-4 pb-4 h1`}
					>
						<FormattedMessage id={title} />
					</h2>
				</div>
			)}
			<div className={genericCarousel.carouselContainer}>
				<div className={genericCarousel.embla}>
					<div
						className={genericCarousel.embla__viewport}
						ref={emblaRefCarousel}
					>
						<div className={genericCarousel.embla__container}>
							{groupedImages.map((group, groupIndex) => (
								<div className={genericCarousel.embla__slide} key={groupIndex}>
									{group.map((logo, index) => (
										<div
											className={`${genericCarousel.embla__slide__group}`}
											style={customCss}
											key={index}
										>
											{logo.href && (
												<a target='_blank' rel='noreferrer' href={logo.href}>
													<Image
														className='position-relative'
														src={logo.path}
														fill
														alt={logo.alt}
														style={{ objectFit: 'cover' }}
														loading='eager'
														priority={true}
														placeholder='blur'
														blurDataURL='/images/loading-image-placeholder.svg'
													></Image>
												</a>
											)}
											{!logo.href && (
												<Image
													className='position-relative'
													src={logo.path}
													fill
													alt={logo.alt}
													style={{ objectFit: 'cover' }}
													loading='eager'
													priority={true}
													placeholder='blur'
													blurDataURL='/images/loading-image-placeholder.svg'
												></Image>
											)}
										</div>
									))}
								</div>
							))}
						</div>
						{showNavigationControls && (
							<div className={genericCarousel.carouselControlsContainer}>
								<PrevButton onClick={scrollPrev} enabled={true} />
								<NextButton onClick={scrollNext} enabled={true} />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
