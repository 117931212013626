// export const REQUEST_STATUSES = {
// 	0: "pending",
// 	1: "active",
// 	2: "received_offer",
// 	3: "accepted_offer",
// 	4: "advance_payment",
// 	5: "implementation",
// 	6: "final_payment",
// 	7: "finalised",
// };

export const AWS_EXPORTS = {
	aws_project_region: process.env.NEXT_PUBLIC_AWS_PROJECT_REGION,
	aws_cognito_identity_pool_id:
		process.env.NEXT_PUBLIC_AWS_COGNITO_IDENTITY_POOL_ID,
	aws_cognito_region: process.env.NEXT_PUBLIC_AWS_COGNITO_REGION,
	aws_user_pools_id: process.env.NEXT_PUBLIC_AWS_USER_POOLS_ID,
	aws_user_pools_web_client_id:
		process.env.NEXT_PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID,
	oauth: {},
	aws_cognito_username_attributes: JSON.parse(
		process.env.NEXT_PUBLIC_AWS_COGNITO_USERNAME_ATTRIBUTES || '[]'
	),
	aws_cognito_social_providers: JSON.parse(
		process.env.NEXT_PUBLIC_AWS_COGNITO_SOCIAL_PROVIDERS || '[]'
	),
	aws_cognito_signup_attributes: JSON.parse(
		process.env.NEXT_PUBLIC_AWS_COGNITO_SIGNUP_ATTRIBUTES || '[]'
	),
	aws_cognito_mfa_configuration:
		process.env.NEXT_PUBLIC_AWS_COGNITO_MFA_CONFIGURATION,
	aws_cognito_mfa_types: JSON.parse(
		process.env.NEXT_PUBLIC_AWS_COGNITO_MFA_TYPES || '[]'
	),
	aws_cognito_password_protection_settings: {
		passwordPolicyMinLength:
			process.env.NEXT_PUBLIC_AWS_COGNITO_PASSWORD_POLICY_MIN_LENGTH,
		passwordPolicyCharacters: JSON.parse(
			process.env.NEXT_PUBLIC_AWS_COGNITO_PASSWORD_POLICY_CHARACTERS || '[]'
		),
	},
	aws_cognito_verification_mechanisms: JSON.parse(
		process.env.NEXT_PUBLIC_AWS_COGNITO_VERIFICATION_MECHANISMS || '[]'
	),
	aws_user_files_s3_bucket: process.env.NEXT_PUBLIC_AWS_USER_FILES_S3_BUCKET,
	aws_user_files_s3_bucket_region:
		process.env.NEXT_PUBLIC_AWS_USER_FILES_S3_BUCKET_REGION,
	aws_email_secret_access_key: process.env.AWS_EMAIL_SECRET_ACCESS_KEY,
	active_campaign_api_key: process.env.ACTIVE_CAMPAIGN_API_KEY,
	google_maps_javascript_apikey:
		process.env.NEXT_PUBLIC_GOOGLE_MAPS_JAVASCRIPT_APIKEY,
	contentful_space_id: process.env.CONTENTFUL_SPACE_ID || '',
	contentful_access_token: process.env.CONTENTFUL_ACCESS_TOKEN || '',
	whatsapp_verify_token: process.env.WHATSAPP_VERIFY_TOKEN || '',
};

interface PriceData {
	[key: number]: number;
}

export const ENV_VARS = {
	SHOW_PARTNER_PAGE: true,
	SHOW_CASA_VERDE: true,
	SHOW_BLOG: false,
	SHOW_CASE_STUDIES: false,
	SHOW_ABOUT_PAGE: true,
	SHOW_LOGIN: true,
	SHOW_SOCIAL_PLATFORMS: true,
	SHOW_PV_COST_CARD: true,
	SHOW_ANNUAL_PROFIT_CARD: true,
	SHOW_UPDATE_PASSWORD_FIELDS: false,
	SHOW_BONUS_FEATURE: false,
	SHOW_PRIVATE_PROJECTS: false,
	CURRENCY: 'RON',
	DEFAULT_SYSTEM_PRICE_PER_KW: 5000,
	DEFAULT_VAT: 9,
	DEFAULT_COMPANY_VAT: 19,
	DEFAULT_CASA_VERDE_FUNDS: 20000,
	ALLOWED_VATS_RATES: [0, 9, 19],
	MIN_PRICE_PER_KWH: 0.8,
	PRICE_PER_KWH: 1.3,
	PRICE_PER_KWH_GOV_CAP: 255,
	INJECTION_PRICE_PER_KWH: 0.45,
	INSOLATION_HOURS_PER_DAY: 3.5,
	PV_SYSTEM_PRICE_PER_KWH: 5500,
	PV_SYSTEM_PRICE_PER_KWH_WITH_VAT: {
		3: 12200,
		5: 18300,
		6: 21300,
		7: 23500,
		8: 25950,
		10: 29500,
		11: 32400,
		12: 38200,
		15: 47750,
		20: 62850,
		25: 79550,
		30: 87700,
		35: 111350,
		40: 127250,
		45: 143100,
		48: 152600,
		50: 159000,
		60: 190800,
		70: 213900,
		80: 244500,
		90: 275000,
		100: 238500,
		150: 357800,
		160: 381700,
		170: 405600,
		180: 429400,
		190: 453200,
		200: 477100,
		400: 892800,
	},
	RECOMMENDED_POWER_PRECISION: 0.5,
	RECOMMENDED_POWER_STEP: 0.5,
	RECOMMENDED_POWER_MAX: 400,
	RECOMMENDED_POWER_MIN: 3,
	INSTALLED_POWER_PRECISION: 0.25,
	INSTALLED_POWER_MAX: 400,
	INSTALLED_POWER_MIN: 1,
	ROOF_SLOPE_PRECISION: 1,
	ROOF_SLOPE_MIN: 0,
	ROOF_SLOPE_MAX: 90,
	ROOF_ORIENTATION_PRECISION: 1,
	ROOF_ORIENTATION_STEP: 1,
	ROOF_ORIENTATION_MIN: 0,
	ROOF_ORIENTATION_MAX: 359,
	ROOF_HEIGHT_PRECISION: 0.1,
	ROOF_HEIGHT_MIN: 0,
	ROOF_HEIGHT_MAX: 20,
	BRANCHING_DISTANCE_PRECISION: 1,
	BRANCHING_DISTANCE_STEP: 1,
	BRANCHING_DISTANCE_MIN: 1,
	BRANCHING_DISTANCE_MAX: 1000,
	PRICES_STEP: 0.1,
	TEXTAREA_MAX_CHARS: 600,
	//REQUEST_STATUSES: REQUEST_STATUSES,
	ALTREAL_PHONE_0: '+40 743 244 712',
	ALTREAL_PHONE_1: '+40 738 888 488',
	ALTREAL_PHONE_2: '+40 737 885 885',
	ALTREAL_ADMIN_NOTIFICATIONS_EMAIL: 'appmail@altreal.ro',
	ALTREAL_CONTACT_EMAIL: 'contact@altreal.ro',
	ALTREAL_ADDRESS: 'Aleea Marghiolilor 64, Ciocanai, Arges',
	ALTREAL_COMPANY_DATA: 'J03/3018/2022 RO47116282',
	ALTREAL_DOMAIN: 'https://altreal.ro',
	ALTREAL_FACEBOOK: 'https://facebook.com/altreal.solar',
	ALTREAL_INSTAGRAM: 'https://www.instagram.com/altreal.solar',
	ALTREAL_LINKEDIN: 'https://www.linkedin.com/company/altreal-solar',
	ALTREAL_ACTIVE_CAMPAIGN_DEALS_URL:
		'https://altreal.activehosted.com/app/deals',
	GOOGLE_MAPS_DEFAULT_ZOOM: 18,
	GOOGLE_PLACES_ALLOWED_COUNTRIES: ['ro'],
	S3_AWS_ACCESS_KEY: '',
	S3_AWS_SECRET_ACCESS_KEY: '',
	AWS_EMAIL_ACCESS_KEY_ID: 'AKIAWJVZMINHE6QLAO6A',
	AWS_EMAIL_VERIFIED_ADDRESS: 'noreply@altreal.ro',
	ACTIVE_CAMPAIGN_API_BASE_URL: 'https://altreal.api-us1.com/api/3',
	ALLOWED_FILE_TYPES: 'image/*,.pdf',
	UPLOAD_FILE_TYPES:
		'.jpeg,.jpg,.png,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	ALLOWED_IMG_TYPES: 'image/*',
	MAX_FILE_SIZE: 30 * 1024 * 1024,
	MAX_BOOKING_SLOTS: 3,
	FB_PIXEL: 1333747743847282,
	AFM_INSTRUCTIONS_URL:
		'https://afm.ro/main/programe/sisteme_fotovoltaice/2023/instructiuni_persoane_fizice_parohii_2023.pdf',
	SOCIAL_LINKEDIN_URL: '',
	GOOGLE_CONVERSION_ID: 11334515157,
	GOOGLE_CONVERSION_LABEL: 'JQ4VCPPP8-8YENXz25wq',
	SUPPORTED_LOCALES: ['ro-RO', 'en-GB'],
	DEFAULT_LOCALE: 'ro-RO',
	GLOBAL_NUMBER_OF_REVIEW_STARS: 5,
	DEFAULT_NUMBER_OF_REVIEWS_DISPLAYED: 10,
};

export const ENTITY_LISTS_API_USER_TYPE = {
	CUSTOMER: 'customer',
	INSTALLER: 'installer',
	ADMIN: 'admin',
};

export const ROMANIAN_COUNTY_LIST = {
	XX: 'Toate',
	AB: 'Alba',
	AR: 'Arad',
	AG: 'Argeș',
	BC: 'Bacău',
	BH: 'Bihor',
	BN: 'Bistrița-Năsăud',
	BR: 'Brăila',
	BV: 'Brașov',
	BZ: 'Buzău',
	CL: 'Călărași',
	CS: 'Caraș-Severin',
	CJ: 'Cluj',
	CT: 'Constanța',
	CV: 'Covasna',
	DB: 'Dâmbovița',
	DJ: 'Dolj',
	GL: 'Galați',
	GR: 'Giurgiu',
	GJ: 'Gorj',
	HR: 'Harghita',
	HD: 'Hunedoara',
	IL: 'Ialomița',
	IS: 'Iași',
	IF: 'Ilfov',
	MM: 'Maramureș',
	MH: 'Mehedinți',
	MS: 'Mureș',
	NT: 'Neamț',
	OT: 'Olt',
	PH: 'Prahova',
	SJ: 'Sălaj',
	SM: 'Satu Mare',
	SB: 'Sibiu',
	SV: 'Suceava',
	TR: 'Teleorman',
	TM: 'Timiș',
	TL: 'Tulcea',
	VS: 'Vaslui',
	VL: 'Vâlcea',
	VN: 'Vrancea',
	B: 'București',
};

export const VOUCHER_CODES = [
	'bbmsc9',
	'4u9crn',
	'e66igx',
	'vm7mkr',
	'pcwk3d',
	'78vp4z',
];
