import { IPriceInterval } from 'data/abstractions/configuratorInterfaces';
import { ENV_VARS } from './constants';
import { UserType } from 'data/models/types/userTypeConfig';
import { v4 as uuidv4 } from 'uuid';

export function sortObj(list: any[], key: string | number, desc = false) {
	function compare(a: any, b: any) {
		a = a[key];
		b = b[key];

		var type =
			typeof a === 'string' || typeof b === 'string'
				? 'string'
				: typeof a === 'boolean' || typeof b === 'boolean'
				? 'boolean'
				: 'number';
		var result;
		if (desc === false) {
			if (type === 'string') result = a.localeCompare(b);
			else if (type === 'boolean') result = a - b;
			else result = a - b;
		} else {
			if (type === 'string') result = b.localeCompare(a);
			else if (type === 'boolean') result = b - a;
			else result = b - a;
		}
		return result;
	}
	return list.sort(compare);
}

export function getRemainingDays(date: any) {
	let localDate = new Date();
	if (typeof date === 'string') {
		const arrayDate = date.match(/^(\d{1,2})\.(\d{1,2})\.(\d{4})$/);
		if (arrayDate !== null) {
			localDate = new Date(
				parseInt(arrayDate[3]),
				parseInt(arrayDate[2]) - 1,
				parseInt(arrayDate[1])
			);
		}
	}
	const diffTime = Math.abs(localDate.getTime() - new Date().getTime());
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
	return diffDays;
}

export const getCurrentDatetime = (
	numberOfDaysToAdd = 0,
	convertToUTCString = false
) => {
	let newDate = new Date(
		new Date().setUTCDate(new Date().getUTCDate() + numberOfDaysToAdd)
	);

	// Display the new date in UTC format
	if (convertToUTCString) return newDate.toUTCString();
	return newDate;
};

export const blobToImage = async (blob: Blob): Promise<HTMLImageElement> => {
	return new Promise(async (resolve, reject) => {
		const url = URL.createObjectURL(blob);
		let img = new Image();
		img.onload = () => {
			URL.revokeObjectURL(url);
			resolve(img);
		};
		img.src = url;
		img.height = 400;
	});
};

export const getSystemPricByRequestedPower = (
	requestedPower: number,
	priceIntervals: IPriceInterval[]
) => {
	for (const priceInterval of priceIntervals) {
		if (
			requestedPower > priceInterval.minimumInstallablePower &&
			requestedPower < priceInterval.maximumInstallablePower
		) {
			return requestedPower * priceInterval.price;
		}
	}
	return requestedPower * ENV_VARS.DEFAULT_SYSTEM_PRICE_PER_KW;
};

export const countDecimals = (num: number) => {
	if (Math.floor(num) === num) {
		return 0;
	}
	return num.toString().split('.')[1].length || 0;
};

export const logScaleTransform = (
	min: number,
	max: number,
	step: number = 1
) => {
	const logMin = Math.log10(min);
	const logMax = Math.log10(max);
	const logRange = logMax - logMin;
	const subUnitaryStep = step < 1 ? 1 / step : step;

	return function (input: number) {
		const normalizedInput = (Math.log10(input) - logMin) / logRange;
		const output = min + normalizedInput * (max - min);
		return Math.round(output * subUnitaryStep) / subUnitaryStep; // Round to nearest quarter
	};
};

export const reverseLogScaleTransform = (
	min: number,
	max: number,
	step: number = 1
) => {
	const logMin = Math.log10(min);
	const logMax = Math.log10(max);
	const logRange = logMax - logMin;
	const subUnitaryStep = step < 1 ? 1 / step : step;

	return function (input: number) {
		const normalizedInput = (input - min) / (max - min);
		const output = Math.pow(10, logMin + normalizedInput * logRange);
		return Math.round(output * subUnitaryStep) / subUnitaryStep; // Round to nearest quarter
	};
};

export const isDefined = (val: any): boolean => {
	if (val === undefined) return false;
	if (val === null) return false;
	return true;
};

export const safeParse = (data: string | Object) => {
	try {
		return typeof data === 'string' ? JSON.parse(data) : data;
	} catch (e) {
		return data;
	}
};

export const getKeyByValue = (obj: UserType, value: number): string | null => {
	for (let key in obj) {
		if (obj.hasOwnProperty(key) && obj[key] === value) {
			return key;
		}
	}
	return null;
};

export const generateCorrelationId = () => {
	const newGuid = uuidv4();
	return newGuid;
};
