import logger from 'helpers/logging';
/**
 * @type {UserConfig}
 */
const pluginConfig = {
	current_lang: 'en',
	autoclear_cookies: true, // default: false
	page_scripts: true, // default: false

	// mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
	// delay: 0,                               // default: 0
	auto_language: 'document', // default: null; could also be 'browser' or 'document'
	// autorun: true,                          // default: true
	// force_consent: false,                   // default: false
	// hide_from_bots: false,                  // default: false
	// remove_cookie_tables: false             // default: false
	// cookie_name: 'cc_cookie',               // default: 'cc_cookie'
	// cookie_expiration: 182,                 // default: 182 (days)
	// cookie_necessary_only_expiration: 182   // default: disabled
	// cookie_domain: location.hostname,       // default: current domain
	// cookie_path: '/',                       // default: root
	// cookie_same_site: 'Lax',                // default: 'Lax'
	// use_rfc_cookie: false,                  // default: false
	// revision: 0,                            // default: 0

	gui_options: {
		consent_modal: {
			layout: 'cloud', // box/cloud/bar
			position: 'bottom center', // bottom/middle/top + left/right/center
			//transition: 'slide', // zoom/slide
			swap_buttons: false, // enable to invert buttons
		},
		settings_modal: {
			layout: 'box', // box/bar
			// position: 'left',           // left/right
			// transition: 'slide', // zoom/slide
		},
	},

	onFirstAction: function (user_preferences: any, cookie: any) {
		// callback triggered only once
		const analyticsEnabled =
			//@ts-ignore
			window.CookieConsentApi.allowedCategory('analytics');
		logger.debug(`analytics ${analyticsEnabled ? 'enabled' : 'disabled'}`);
	},

	onAccept: function (cookie: any) {
		// ...
	},

	onChange: function (cookie: any, changed_preferences: any) {
		// ...
	},

	languages: {
		en: {
			consent_modal: {
				title: 'We use cookies!',
				description:
					'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
				primary_btn: {
					text: 'Accept all',
					role: 'accept_all', // 'accept_selected' or 'accept_all'
				},
				secondary_btn: {
					text: 'Reject all',
					role: 'accept_necessary', // 'settings' or 'accept_necessary'
				},
			},
			settings_modal: {
				title: 'Cookie Settings',
				save_settings_btn: 'Save settings',
				accept_all_btn: 'Accept all',
				reject_all_btn: 'Reject all',
				close_btn_label: 'Close',
				cookie_table_headers: [
					{ col1: 'Name' },
					{ col2: 'Domain' },
					{ col3: 'Expiration' },
					{ col4: 'Description' },
				],
				blocks: [
					{
						title: 'Cookie usage 📢',
						description:
							'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="/privacy-policy" target="_blank" class="cc-link">privacy policy</a>.',
					},
					{
						title: 'Strictly necessary cookies',
						description:
							'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
						toggle: {
							value: 'necessary',
							enabled: true,
							readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
						},
					},
					{
						title: 'Performance and Analytics cookies',
						description:
							'These cookies allow the website to remember the choices you have made in the past',
						toggle: {
							value: 'analytics', // your cookie category
							enabled: false,
							readonly: false,
						},
						cookie_table: [
							// list of all expected cookies
							{
								col1: '_ga',
								col2: 'google.com',
								col3: '2 years',
								col4: 'Permanent cookie used to distinguish users.',
								col5: '<a href="https://policies.google.com/privacy?hl=en-US" style="word-break: break-all;">Privacy Policy</a>',
							},
							{
								col1: '_gat',
								col2: 'google.com',
								col3: '1 minute',
								col4: 'Permant Cookie used to throttle request rate. If Google Analytics is deployed via Google Tag Manager, this cookie will be named _dc_gtm_<property- id>.',
								col5: '<a href="https://policies.google.com/privacy?hl=en-US" style="word-break: break-all;">Privacy Policy</a>',
							},
							{
								col1: '_gid',
								col2: 'google.com',
								col3: '1 day',
								col4: 'Permanent Cookie used to distinguish users.',
								col5: '<a href="https://policies.google.com/privacy?hl=en-US" style="word-break: break-all;">Privacy Policy</a>',
							},
						],
					},
					{
						title: 'Advertisement and Targeting cookies',
						description:
							'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
						toggle: {
							value: 'targeting',
							enabled: false,
							readonly: false,
						},
					},
					// {
					// 	title: "More information",
					// 	description:
					// 		'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
					// },
				],
			},
		},
		'ro-RO': {
			consent_modal: {
				title: 'Folosim cookies!',
				description:
					'Salut, acest site utilizează cookie-uri esențiale pentru a asigura funcționarea corectă a acestuia și cookie-uri de urmărire pentru a înțelege modul în care interacționați cu el. Acestea din urmă vor fi setate numai după consimțământ. <button type="button" data-cc="c-settings" class="cc-link">Permiteți-mi să aleg</button>',
				primary_btn: {
					text: 'Acceptă tot',
					role: 'accept_all', // 'accept_selected' or 'accept_all'
				},
				secondary_btn: {
					text: 'Respinge tot',
					role: 'accept_necessary', // 'settings' or 'accept_necessary'
				},
			},
			settings_modal: {
				title: 'Setări Cookie',
				save_settings_btn: 'Salvează setări',
				accept_all_btn: 'Acceptă tot',
				reject_all_btn: 'Respinge tot',
				close_btn_label: 'Închide',
				cookie_table_headers: [
					{ col1: 'Nume' },
					{ col2: 'Domeniu' },
					{ col3: 'Expirare' },
					{ col4: 'Descriere' },
				],
				blocks: [
					{
						title: 'Utilizare cookies 📢',
						description:
							'Utilizez cookie-uri pentru a asigura funcționalitățile de bază ale site-ului și pentru a îmbunătăți experiența dvs. online. Puteți opta pentru fiecare categorie să vă înscrieți/renunțați oricând doriți. Pentru mai multe detalii cu privire la cookie-uri și alte date sensibile, vă rugăm să citiți <a href="/privacy-policy" target="_blank" class="cc-link">politica de confidențialitate</a> completă.',
					},
					{
						title: 'Cooki-uri strict nececesare',
						description:
							'Aceste cookies sunt esențiale pentru buna funcționare a site-ului. Fără aceste cookies, site-ul nu ar funcționa corect.',
						toggle: {
							value: 'necessary',
							enabled: true,
							readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
						},
					},
					{
						title: 'Cookies de performanță și analiză',
						description:
							'Aceste cookies permit site-ului web să își amintească alegerile pe care le-ai făcut în trecut.',
						toggle: {
							value: 'analytics', // your cookie category
							enabled: false,
							readonly: false,
						},
						cookie_table: [
							// list of all expected cookies
							{
								col1: '_ga',
								col2: 'google.com',
								col3: '2 ani',
								col4: 'Cookie permanent utilizat pentru a distinge utilizatorii.',
								col5: '<a href="https://policies.google.com/privacy?hl=ro" style="word-break: break-all;">Politica de confidențialitate</a>',
							},
							{
								col1: '_gat',
								col2: 'google.com',
								col3: '1 minut',
								col4: 'Cookie permanent utilizat pentru a regla rata de solicitări. Dacă Google Analytics este implementat prin intermediul Google Tag Manager, acest cookie va fi numit dc_gtm<property-id>.',
								col5: '<a href="https://policies.google.com/privacy?hl=ro" style="word-break: break-all;">Politica de confidențialitate</a>',
							},
							{
								col1: '_gid',
								col2: 'google.com',
								col3: '1 zi',
								col4: 'Cookie permanent utilizat pentru a distinge utilizatorii.',
								col5: '<a href="https://policies.google.com/privacy?hl=ro" style="word-break: break-all;">Politica de confidențialitate</a>',
							},
						],
					},
					{
						title: 'Cookie-uri de publicitate și direcționare',
						description:
							'Aceste cookie-uri colectează informații despre modul în care folosești site-ul web, care pagini le-ai vizitat și ce legături ai accesat. Toate datele sunt anonimizate și nu pot fi folosite pentru a te identifica.',
						toggle: {
							value: 'targeting',
							enabled: false,
							readonly: false,
						},
					},
					// {
					// 	title: "More information",
					// 	description:
					// 		'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
					// },
				],
			},
		},
	},
};

export default pluginConfig;
