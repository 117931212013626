import { IntlShape } from 'react-intl';
import { ENV_VARS } from './constants';

export const getCompanyYearsOfExistence = (registrationDate: Date) => {
	const currentDate = new Date();
	let yearsOfExistence =
		currentDate.getFullYear() - registrationDate.getFullYear();

	// Adjust for cases when the exact day/month in the current year hasn't occurred yet
	const registrationAnniversary = new Date(
		currentDate.getFullYear(),
		registrationDate.getMonth(),
		registrationDate.getDate()
	);
	const sixMonthsLater = new Date(registrationAnniversary);
	sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);

	if (currentDate >= sixMonthsLater) {
		yearsOfExistence += 1; // increment year if it's over 0.5
	} else if (currentDate < registrationAnniversary) {
		yearsOfExistence -= 1; // decrement year if we haven't reached the anniversary yet
	}

	return Math.max(1, yearsOfExistence);
};

export const isPassedDate = (date: Date) => {
	const now = new Date();
	now.setHours(24, 0, 0, 0);
	const expirationDate = new Date(date);
	expirationDate.setHours(24, 0, 0, 0);
	return now > expirationDate;
};

export const getHumanReadbleAge = (date: Date, intl: IntlShape) => {
	const now = new Date();
	const registered = new Date(date);

	// Convert Dates to timestamps for arithmetic operation
	const diffTime = Math.abs(now.getTime() - registered.getTime());
	const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

	const diffMonths = Math.floor(diffDays / 30.44); // Average days per month
	const diffYears = Math.floor(diffDays / 365.25); // Average days per year, accounting for leap years

	if (diffYears > 0) {
		return `${diffYears} ${intl.formatMessage({
			id: `component.time.year${diffYears > 1 ? 's' : ''}`,
		})}`;
	} else if (diffMonths > 0) {
		return `${diffMonths} ${intl.formatMessage({
			id: `component.time.month${diffMonths > 1 ? 's' : ''}`,
		})}`;
	} else {
		return `${diffDays} ${intl.formatMessage({
			id: `component.time.day${diffDays > 1 ? 's' : ''}`,
		})}`;
	}
};

export const formatDateString = (
	date: string,
	locale = ENV_VARS.DEFAULT_LOCALE
) => {
	const dateObj = new Date(date);
	return new Intl.DateTimeFormat(locale, {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	}).format(dateObj);
};
