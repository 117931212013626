import React, { useEffect, useState } from 'react';
import navigations from 'styles/Navigation.module.scss';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import CtaButton from '../ui/ctaButton';
import { useIntl } from 'react-intl';
import { ENV_VARS } from 'helpers/constants';
import logger from 'helpers/logging';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import { isDefined } from 'helpers/helpers';

interface IMenu {
	isMobile: boolean;
	expanded: boolean;
	dbUser?: any;
	clicked?: () => void;
}

export default function Menu({ isMobile, expanded, clicked, dbUser }: IMenu) {
	const intl = useIntl();
	const router = useRouter();
	const { user, signOut } = useAuthenticator((context) => [context.user]);
	const [localUser, setLocalUser] = useState<any>(null);

	const [visibility, setVisibility] = React.useState('');
	const [ctaTargetHref, setCtaTargetHref] = useState('/solicita-oferta');

	useEffect(() => {
		const shouldBeVisibile = isMobile ? (expanded ? '' : 'd-none') : '';
		setVisibility(shouldBeVisibile);
	}, [isMobile, expanded]);

	useEffect(() => {
		if (window.location.pathname == '/') {
			setCtaTargetHref('/solicita-oferta');
		} else {
			setCtaTargetHref(`${window.location.origin}/solicita-oferta`);
		}
	}, []);

	useEffect(() => {
		if (dbUser) {
			setLocalUser(dbUser);
		} else if (user) {
			setLocalUser(user);
		}
	}, [dbUser, user]);

	const signOutHandler = async () => {
		try {
			await Auth.signOut();
			await router.push('/login');
		} catch (err) {
			logger.debug(err);
		}
	};

	return (
		<ul
			className={`${navigations.menu} ${
				expanded ? navigations.expanded + ' container' : ''
			} ${isMobile ? navigations.mobile : ''}`}
		>
			{isMobile && (
				<li className={`${navigations.borderRight} ${visibility}`}>
					<Link href='/' onClick={clicked}>
						<FormattedMessage
							id='component.nav.homepage'
							defaultMessage='Homepage'
						/>
					</Link>
				</li>
			)}
			<>
				{ENV_VARS.SHOW_ABOUT_PAGE && (
					<li className={`${navigations.borderRight} ${visibility}`}>
						<Link href='/about' onClick={clicked}>
							<FormattedMessage
								id='component.nav.aboutus'
								defaultMessage='About us'
							/>
						</Link>
					</li>
				)}
				{ENV_VARS.SHOW_PARTNER_PAGE && (
					<li className={`${navigations.borderRight} ${visibility}`}>
						<Link href='/partners' onClick={clicked}>
							<FormattedMessage
								id='component.nav.partners'
								defaultMessage='Partners'
							/>
						</Link>
					</li>
				)}
				{ENV_VARS.SHOW_CASA_VERDE && (
					<li className={`${navigations.borderRight} ${visibility}`}>
						<Link href='/casaverde' onClick={clicked}>
							<FormattedMessage
								id='component.nav.casa_verde'
								defaultMessage='Casa Verde'
							/>
						</Link>
					</li>
				)}
				{(ENV_VARS.SHOW_BLOG || ENV_VARS.SHOW_CASE_STUDIES) &&
					(isMobile ? (
						<>
							<li>
								<Link href='/blog/posts' onClick={clicked}>
									<FormattedMessage
										id='component.nav.blog'
										defaultMessage='Blog'
									/>
								</Link>
							</li>
							<li>
								<Link href='/case-studies/studies' onClick={clicked}>
									<FormattedMessage
										id='component.nav.case_studies'
										defaultMessage='Case studies'
									/>
								</Link>
							</li>
						</>
					) : (
						<li className={``}>
							<div className='dropdown-center'>
								<button
									className='btn'
									type='button'
									id={`resourcesDropdownButton`}
									data-bs-toggle='dropdown'
									aria-expanded='false'
								>
									<FormattedMessage
										id='component.nav.resources'
										defaultMessage='Resources'
									/>
								</button>
								<ul
									className='dropdown-menu'
									aria-labelledby={`#resourcesDropdownButton`}
								>
									{ENV_VARS.SHOW_BLOG && (
										<li>
											<Link
												href='/blog/posts'
												className='dropdown-item'
												onClick={clicked}
											>
												<FormattedMessage
													id='component.nav.blog'
													defaultMessage='Blog'
												/>
											</Link>
										</li>
									)}
									{ENV_VARS.SHOW_CASE_STUDIES && (
										<li>
											<Link
												href='/case-studies/studies'
												className='dropdown-item'
												onClick={clicked}
											>
												<FormattedMessage
													id='component.nav.case_studies'
													defaultMessage='Case studies'
												/>
											</Link>
										</li>
									)}
								</ul>
							</div>
						</li>
					))}
			</>

			{!isMobile && (
				<li className={`${visibility}`}>
					<CtaButton
						label={intl.formatMessage({
							id: 'component.nav.get_quote',
							defaultMessage: 'Get quote',
						})}
						href={ctaTargetHref}
						// handler={(e) => {
						// 	const targetSection = document.querySelector('#estimator');
						// 	if (targetSection !== null) {
						// 		e.preventDefault();
						// 		const offsetTop =
						// 			(targetSection as HTMLElement)?.offsetTop ?? 0;
						// 		scroll({
						// 			top: offsetTop,
						// 			behavior: 'smooth',
						// 		});
						// 	}
						// }}
						small={true}
					></CtaButton>
				</li>
			)}
			{ENV_VARS.SHOW_LOGIN && (
				<>
					{isDefined(localUser) && (
						<li className={`${visibility}`}>
							<Link
								className={`${'btn btn-secondary btn-round'}`}
								href='/profile'
								onClick={clicked}
							>
								<FormattedMessage
									id='component.nav.dashboard'
									defaultMessage='Dashboard'
								/>
							</Link>
						</li>
					)}
					<li className={`${visibility}`}>
						{!isDefined(localUser) && (
							<Link
								href='/login'
								className={`${'text-uppercase btn btn-primary btn-round'}`}
								onClick={clicked}
							>
								<FormattedMessage
									id='component.nav.login'
									defaultMessage='Login'
								/>
							</Link>
						)}
						{isDefined(localUser) && (
							<button
								className={`${'text-uppercase btn btn-danger btn-round'}`}
								onClick={() => {
									clicked != undefined ? clicked() : null;
									signOutHandler();
								}}
							>
								<FormattedMessage
									id='component.nav.sign_out'
									defaultMessage='Log Out'
								/>
							</button>
						)}
					</li>
				</>
			)}
		</ul>
	);
}
