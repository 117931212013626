import React, { useState } from 'react';
import Image from 'next/image';
import footers from 'styles/Footer.module.scss';
import FooterLinks from './footerLinks';
import SocialMediaLinks from './socialMediaLinks';
import PhotonAnimation from '../animation/photonAnimation';
import { ENV_VARS } from 'helpers/constants';

export default function Footer() {
	const [currentYear] = useState(new Date().getFullYear());
	return (
		<footer className={`${footers.footer} d-flex align-items-end`}>
			<div className={`${footers.footerContainer} container`}>
				<div className={footers.footerLinksContainer}>
					<Image
						src='/logo/altreal-logo-desktop-light.png'
						alt='alt.real logo desktop'
						className={footers.footerLogo}
						fill
					/>
					<div>
						<FooterLinks></FooterLinks>
					</div>
					<div></div>
				</div>
				<div className={footers.copyrightContainer}>
					<div className={footers.copyright}>
						<span>Copyright ALT.REAL {currentYear}</span>
						<span className='ms-3'>
							<PhotonAnimation icon={'©'} angle={12}></PhotonAnimation>
						</span>
					</div>
					{ENV_VARS.SHOW_SOCIAL_PLATFORMS && (
						<SocialMediaLinks></SocialMediaLinks>
					)}
				</div>
			</div>
		</footer>
	);
}
