import { useRouter } from 'next/router';
import { IntlProvider, IntlShape, useIntl } from 'react-intl';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useEffect } from 'react';
//import { Amplify } from "aws-amplify";
import { Amplify, withSSRContext } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import CookieConsentComponent from 'components/gdpr/CookieConsentComponent';
import 'styles/globals/_cookie-consent.scss';
import 'styles/globals/_imagepresenter.scss';
import { AWS_EXPORTS, ENV_VARS } from 'helpers/constants';
import Intl from 'helpers/intlHelpers';

Amplify.configure({
	...AWS_EXPORTS,
	ssr: true,
});
//Amplify.Logger.LOG_LEVEL = 'DEBUG';

// Auth from SSR and not directly from aws-amplify
const { Auth } = withSSRContext();
Auth.configure({
	region: process.env.NEXT_PUBLIC_AWS_PROJECT_REGION,
	userPoolId: process.env.NEXT_PUBLIC_AWS_USER_POOLS_ID,
	userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_USER_POOLS_WEB_CLIENT_ID,
	mandatorySignIn: false,
	// Set this only if you wish to use cookies to storage otherwise ignore it
	// cookieStorage: {
	// 	domain: "localhost",
	// 	// Set true if is a domain with https. For localhost set it to false
	// 	secure: false,
	// 	path: "/",
	// 	expires: 2,
	// },
});

import { getAuthFormTranslations } from '../components/platform/authentication/authentication';

import 'bootstrap/dist/css/bootstrap.css';
import 'styles/globals/globals.scss';
import 'styles/globals/_authentication.scss';

import type { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';
import Dinero from 'dinero.js';
import DefaultHeader from 'components/defaultHeader';
import { RouterProvider } from 'helpers/navigationContext';
import CustomHeader from 'components/customHeader';
import Blog from './blog/posts';
import BlogPost from 'pages/blog/post/[id]';
import CaseStudies from './case-studies/studies';
import CaseStudy from './case-studies/study/[id]';

const queryClient = new QueryClient();

export default function App({ Component, pageProps }: AppProps) {
	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.bootstrap = require('bootstrap/dist/js/bootstrap');
		}
	}, []);
	const { locale, defaultLocale } = useRouter();
	const detectedLocale = locale ?? 'ro-RO';

	// used for pages translations
	const intl = Intl(detectedLocale, defaultLocale);

	// used for Cognito authentication translations
	const authFormTranslations = getAuthFormTranslations(detectedLocale);
	I18n.putVocabularies(authFormTranslations);
	I18n.setLanguage(detectedLocale);

	Dinero.globalLocale = detectedLocale;
	//Dinero.defaultPrecision = 2

	return (
		<RawIntlProvider value={intl}>
			<DndProvider backend={HTML5Backend}>
				<Authenticator.Provider>
					<QueryClientProvider client={queryClient}>
						<DefaultHeader></DefaultHeader>
						{Component !== Blog &&
						Component !== BlogPost &&
						Component !== CaseStudies &&
						Component !== CaseStudy ? (
							<CustomHeader
								title={intl.formatMessage({ id: 'component.seo.title' })}
								description={intl.formatMessage({
									id: 'component.seo.description',
								})}
								keywords={intl.formatMessage({
									id: 'component.seo.keywords',
								})}
								img={'/apple-touch-icon.png'}
								locale={intl.locale}
								url={ENV_VARS.ALTREAL_DOMAIN}
							></CustomHeader>
						) : null}
						<RouterProvider>
							<Component {...pageProps} />
						</RouterProvider>
						<CookieConsentComponent />
						{/* <ReactQueryDevtools /> */}
					</QueryClientProvider>
				</Authenticator.Provider>
			</DndProvider>
		</RawIntlProvider>
	);
}
