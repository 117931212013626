import { IReview } from 'data/abstractions/configuratorInterfaces';
import ratings from 'styles/ui/Rating.module.scss';
import tags from 'styles/ui/Tag.module.scss';
// @ts-ignore
import ReactStarsRating from 'react-awesome-stars-rating';
import { ENV_VARS } from 'helpers/constants';
import { faCheckCircle, faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { formatDateString } from 'helpers/dateHelpers';

interface ITagsComponent {
	tags: string[];
}
export default function TagsComponent(props: ITagsComponent) {
	const tagList = props.tags;

	return (
		<>
			{tagList && tagList.length > 0 && (
				<div className={`${tags.tags} row m-0`}>
					{tagList?.map((tag, index) => {
						return (
							<div
								className={`${tags.tag} d-flex align-items-center w-auto`}
								key={index}
							>
								<div className='d-flex align-items-center'>
									<FontAwesomeIcon icon={faTag}></FontAwesomeIcon>
								</div>
								<div className='text-muted'>{tag}</div>
							</div>
						);
					})}
				</div>
			)}
		</>
	);
}
