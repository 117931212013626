import { ENV_VARS } from 'helpers/constants';
import React from 'react';
import { Helmet } from 'react-helmet';

interface IDefaultHeader {
	locale?: string;
}

export default function DefaultHeader({ locale }: IDefaultHeader) {
	return (
		<Helmet>
			<meta property='og:type' content='website' />
			<meta property='og:image:width' content='360' />
			<meta property='og:image:height' content='360' />
			<meta property='og:image:alt' content='alt.real logo' />
			<meta property='og:locale' content={locale} />
			<meta name='application-name' content='Alt.Real' />
			<meta name='robots' content='index, follow' />
			<meta name='revisit-after' content='1 days' />
			<meta name='viewport' content='width=device-width, initial-scale=1' />
			<meta
				name='msapplication-task'
				content='name=CereOferte;action-uri=/apple-touch-icon.png'
			/>
			<meta
				name='msapplication-window'
				content='width=device-width;height=device-height'
			/>
			<meta charSet='UTF-8' />
			<meta name='msapplication-TileImage' content='/apple-touch-icon.png' />
			<link
				rel='apple-touch-icon'
				sizes='180x180'
				href='/apple-touch-icon.png'
			/>
			<link
				rel='icon'
				type='image/png'
				sizes='32x32'
				href='/favicon-32x32.png'
			/>
			<link
				rel='icon'
				type='image/png'
				sizes='16x16'
				href='/favicon-16x16.png'
			/>
			<link rel='manifest' href='/site.webmanifest' />
			<link rel='alternate' href='https://alreal.ro/' hrefLang='x-default' />
			<link rel='alternate' href='https://alreal.ro/ro-RO' hrefLang='ro' />
			<link rel='alternate' href='https://alreal.ro/en-GB' hrefLang='en' />
			<link rel='canonical' href={ENV_VARS.ALTREAL_DOMAIN}></link>
			{/* <link rel="preconnect" href="https://fonts.googleapis.com" />
			<link
				rel="preconnect"
				href="https://fonts.gstatic.com"
				crossOrigin="true"
			/>
			<link
				href="https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=fallback"
				rel="stylesheet"
			/> */}
		</Helmet>
	);
}
