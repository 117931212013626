import React from 'react';
import Link from 'next/link';
import footers from 'styles/Footer.module.scss';
import { FormattedMessage } from 'react-intl';
import { ENV_VARS } from 'helpers/constants';

export default function FooterLinks() {
	return (
		<ul className={`${footers.footerLinks}`}>
			<li>
				<Link href='/'>
					<FormattedMessage
						id='component.footer.home_page'
						defaultMessage='Homepage'
					/>
				</Link>
			</li>
			{ENV_VARS.SHOW_CASE_STUDIES && (
				<li className={`${footers.borderRight} `}>
					<Link href='/case-studies/studies'>
						<FormattedMessage
							id='component.footer.case_studies'
							defaultMessage='Case studies'
						/>
					</Link>
				</li>
			)}
			{ENV_VARS.SHOW_ABOUT_PAGE && (
				<li>
					<Link href='/about'>
						<FormattedMessage
							id='component.footer.about_us'
							defaultMessage='About us'
						/>
					</Link>
				</li>
			)}
			{ENV_VARS.SHOW_BLOG && (
				<li className={``}>
					<Link href='/blog/posts'>
						<FormattedMessage
							id='component.footer.blog'
							defaultMessage='Blog'
						/>
					</Link>
				</li>
			)}
			{ENV_VARS.SHOW_PARTNER_PAGE && (
				<li>
					<Link href='/partners'>
						<FormattedMessage
							id='component.footer.partners'
							defaultMessage='Partners'
						/>
					</Link>
				</li>
			)}
			<li className={``}>
				<Link href='/contact' className={``}>
					<FormattedMessage
						id='component.footer.contact'
						defaultMessage='Contact'
					/>
				</Link>
			</li>
			<li className={``}>
				<Link target='_blank' href='/terms-and-conditions'>
					<FormattedMessage
						id='component.footer.terms_and_conditions'
						defaultMessage='terms and conditions'
					></FormattedMessage>
				</Link>
			</li>
			<li className={``}>
				<Link target='_blank' href='/privacy-policy'>
					<FormattedMessage
						id='component.footer.privacy_policy'
						defaultMessage='privacy policy'
					></FormattedMessage>
				</Link>
			</li>
			<li className={``}>
				<Link target='_blank' href='https://anpc.ro/ce-este-sal/'>
					<FormattedMessage
						id='component.footer.anpc.sal'
						defaultMessage='ANPC - SAL'
					></FormattedMessage>
				</Link>
			</li>
			<li className={``}>
				<Link
					target='_blank'
					href='https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO'
				>
					<FormattedMessage
						id='component.footer.anpc.sol'
						defaultMessage='ANPC - SOL'
					></FormattedMessage>
				</Link>
			</li>
		</ul>
	);
}
