import {
	createContext,
	useContext,
	useState,
	useEffect,
	ReactNode,
} from 'react';
import { NextRouter, useRouter as useNextRouter } from 'next/router';

type RouterState = {
	router: NextRouter | null;
	prevURL: string | null;
};

type RouterProviderProps = {
	children: ReactNode;
};

const RouterContext = createContext<RouterState | null>(null);

export const RouterProvider: React.FC<RouterProviderProps> = ({ children }) => {
	const router = useNextRouter();
	const [prevURL, setPrevURL] = useState<string | null>(null);

	useEffect(() => {
		const handleRouteChange = (url: string) => {
			setPrevURL(url);
		};

		router?.events.on('routeChangeStart', handleRouteChange);

		return () => {
			router?.events.off('routeChangeStart', handleRouteChange);
		};
	}, [router]);

	return (
		<RouterContext.Provider value={{ router, prevURL }}>
			{children}
		</RouterContext.Provider>
	);
};

export const useAppRouter = (): RouterState => {
	const context = useContext(RouterContext);
	if (!context)
		throw new Error(
			'Router context is undefined, ensure RouterProvider is set up correctly'
		);
	return context;
};
