import {
	getPostDate,
	getPostSummary,
	getPostTitle,
} from 'helpers/contentfulHelpers';
import React from 'react';

const StudyStructuredData = ({ post, locale }: any) => {
	const structuredData = {
		'@context': 'http://schema.org',
		'@type': 'Article',
		headline: getPostTitle(post),
		datePublished: getPostDate(post, locale),
		description: getPostSummary(post),
		author: {
			'@type': 'Person',
			name: 'Alt.Real',
		},
	};

	return (
		<script
			type='application/ld+json'
			dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
		/>
	);
};

export default StudyStructuredData;
