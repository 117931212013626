import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, Document } from '@contentful/rich-text-types';
import { ReactNode } from 'react';
import { formatDateString } from './dateHelpers';

type Mark = {
	type: string;
};

type ContentNode = {
	nodeType: string;
	value?: string;
	marks?: Mark[];
	content?: ContentNode[];
	data?: any;
};

type DocumentNode = {
	nodeType: string;
	content: ContentNode[];
	data: any;
};

export type OrderParam = 'sys.createdAt' | '-sys.createdAt';

export const getPostImgUrls = (post: any) => {
	return (
		post.fields.assets?.map(
			(asset: any) => `https:${asset.fields.file.url}`
		) ?? ['/images/loading-image-placeholder.svg']
	);
};
export const getPostTitle = (post: any) => {
	const title = post.fields.title;
	const limit = 250;

	if (title.length > limit) {
		const trimmedTitle = title.substring(0, limit);
		const lastSpaceIndex = trimmedTitle.lastIndexOf(' ');

		if (lastSpaceIndex > 0) {
			return trimmedTitle.substring(0, lastSpaceIndex) + '...';
		} else {
			return trimmedTitle + '...';
		}
	} else {
		return title;
	}
};
export const getPostSlug = (post: any) => {
	return post.fields.slug;
};

const ACCENT_STYLES = {
	QUOTATION_MARKS:
		'<span style="color: #24ff00; font-weight: bold; padding-left: 0.25rem; padding-right: 0.25rem;">',
};

const applyStyling = (node: ContentNode): string => {
	if (node.nodeType === 'text') {
		let text = node.value || '';

		if (node.marks && node.marks.some((mark) => mark.type === 'italic')) {
			text = `<i>${text}</i>`;
		}
		return text.replace(/"/g, `${ACCENT_STYLES.QUOTATION_MARKS}"</span>`);
	} else if (node.content) {
		return node.content.map(applyStyling).join('');
	}
	return '';
};

const options = {
	renderMark: {
		[MARKS.ITALIC]: (text: ReactNode) => <i>{text}</i>,
	},
	renderText: (text: string) => {
		const replacedText = text.replace(
			/"/g,
			(match) => `${ACCENT_STYLES.QUOTATION_MARKS}${match}</span>`
		);
		// React requires 'dangerouslySetInnerHTML' to render HTML directly
		return <span dangerouslySetInnerHTML={{ __html: replacedText }} />;
	},
};

export const getPostBody = (post: any) => {
	return documentToReactComponents(post.fields.body, options);
};

export const getPostTags = (post: any) => {
	return post.fields?.tags ?? [];
};
export const getPostRating = (post: any) => {
	return post.fields.rating;
};
export const getPostSummary = (post: any): string => {
	return post.fields.summary;
};
export const getPostDate = (post: any, locale: string) => {
	return post.fields.date ? formatDateString(post.fields.date, locale) : '-';
};
