export const CAROUSEL_OPTIONS = { loop: true, draggable: true };
export const CAROUSEL_IMGS = [
	{
		path: '/images/featuredIn/stirile-protv-logo.jpeg',
		alt: 'stirile protv',
		href: 'https://stirileprotv.ro/stiri/social/apar-listele-cu-firmele-eligibile-pentru-programul-casa-verde-la-ce-trebuie-sa-fie-atenti-oamenii-toate-sunt-chinezesti.html',
	},
	{
		path: '/images/featuredIn/zf-logo.png',
		alt: 'ziarul financiar',
		href: 'https://www.zf.ro/zf-live/zf-live-lucian-alexandru-toader-fondator-ceo-real-intentia-noastra-21968354',
	},
	{
		path: '/images/featuredIn/adevarul-logo.svg',
		alt: 'adevarul.ro',
		href: 'https://adevarul.ro/economie/idee-romaneasca-slefuita-la-amsterdam-cu-bani-din-2274935.html',
	},
	{
		path: '/images/featuredIn/profit-logo.jpeg',
		alt: 'profit.ro',
		href: 'https://www.profit.ro/antreprenor-de-romania/profit-news-tv-antreprenor-de-romania-lucian-alexandru-toader-fondator-alt-real-ne-dorim-ca-in-urmatorii-2-ani-sa-ne-extindem-si-in-europa-de-sud-est-oportunitatea-e-una-clara-21279132',
	},
	{
		path: '/images/featuredIn/green-start-up-logo.svg',
		alt: 'green start energy',
		href: 'https://green.start-up.ro/ro/un-nou-jucator-pe-piata-energiei-verzi-in-romania-se-lanseaza-marketplace-ul-alt-real/',
	},
	{
		path: '/images/featuredIn/invest-energy-logo.png',
		alt: 'invest energy',
		href: 'https://www.investenergy.ro/s-a-lansat-alt-real-primul-marketplace-din-domeniul-energiei-verzi/',
	},
	{
		path: '/images/featuredIn/money-buzz-logo.png',
		alt: 'money buzz',
		href: 'https://moneybuzz.ro/s-a-lansat-alt-real-start-up-in-domeniul-energiei-verzi-conceput-la-amsterdam/',
	},
	{
		path: '/images/featuredIn/biz-logo.png',
		alt: 'revista biz',
		href: 'https://www.revistabiz.ro/a-aparut-primul-marketplace-din-domeniul-energiei-verzi-alt-real/',
	},
	{
		path: '/images/featuredIn/biz-plus-logo.webp',
		alt: 'biz plus',
		href: 'https://www.bizplus.ro/a-fost-lansat-alt-real-primul-marketplace-din-domeniul-energiei-verzi/',
	},
	{
		path: '/images/featuredIn/e-nergia-logo.jpeg',
		alt: 'e-nergia',
		href: 'https://e-nergia.ro/se-lanseaza-alt-real-primul-marketplace-din-domeniul-energiei-verzi/',
	},
	{
		path: '/images/featuredIn/energy-center-logo.png',
		alt: 'energy center',
		href: 'https://energy-center.ro/actualitate-news/se-lanseaza-alt-real-primul-marketplace-din-domeniul-energiei-verzi/',
	},
];
