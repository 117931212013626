import {
	createIntl,
	createIntlCache,
	IntlShape,
	MessageFormatElement,
} from 'react-intl';
import ro from '../lang/ro-RO.json';
import en from '../lang/en-GB.json';

export const messages = [
	{
		locale: 'ro-RO',
		translations: ro,
	},
	{
		locale: 'en-GB',
		translations: en,
	},
];

const Intl = (locale: string, defaultLocale: string | undefined) => {
	const cache = createIntlCache();

	// used for pages translations
	const intl = createIntl(
		{
			locale: locale,
			messages: messages.find((x) => x.locale === locale ?? 'ro-RO')
				?.translations,
			defaultLocale: defaultLocale || 'ro-RO',
		},
		cache
	);

	return intl;
};

export default Intl;
