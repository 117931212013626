import Head from 'next/head';
import React from 'react';
import { Helmet } from 'react-helmet';

interface ICustomHeader {
	title?: string;
	description?: string;
	keywords?: string;
	locale?: string;
	img?: string;
	url?: string;
}

export default function CustomHeader({
	title,
	description,
	keywords,
	locale,
	img,
	url,
}: ICustomHeader) {
	return (
		<>
			<Head>
				<title>{title}</title>
			</Head>
			<Helmet>
				<meta name='description' content={description} />
				<meta name='keywords' content={keywords} />
				<meta property='og:title' content={title} />
				<meta property='og:description' content={description} />
				<meta property='og:url' content={url} />
				<meta property='og:site_name' content={title} />
				<meta property='og:image' content={img} />
				<meta name='msapplication-tooltip' content={title} />
			</Helmet>
		</>
	);
}
