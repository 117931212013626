import contentfulClient from 'contentful/client';
import cards from 'styles/ui/CardComponents.module.scss';
import Nav from 'components/navigation/navigation';
import blogs from 'styles/pages/Blog.module.scss';
import Footer from 'components/footer/footer';
import Image from 'next/image';
import Link from 'next/link';
import { FormattedMessage, useIntl } from 'react-intl';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetStaticPropsContext } from 'next';
import { ENV_VARS } from 'helpers/constants';
import {
	getPostDate,
	getPostImgUrls,
	getPostSlug,
	getPostSummary,
	getPostTags,
	getPostTitle,
} from 'helpers/contentfulHelpers';
import TagsComponent from 'components/ui/modules/TagsComponent';
import CustomHeader from 'components/customHeader';
import StudyStructuredData from 'data/structuredData/caseStudyStructuredData';

export async function getStaticProps(context: GetStaticPropsContext) {
	const locale = context.locale ?? ENV_VARS.DEFAULT_LOCALE;
	const posts = await contentfulClient.getEntries({
		content_type: 'caseStudy',
		locale: locale,
		order: ['-fields.date'],
	});
	return {
		props: {
			posts: posts.items,
			locale: locale,
		},
		revalidate: 10, // In seconds
	};
}

export default function CaseStudies({ posts, locale }: any) {
	const intl = useIntl();
	return (
		<div>
			<CustomHeader
				title={intl.formatMessage({ id: `component.seo.case_studies.title` })}
				description={intl.formatMessage({
					id: `component.seo.case_studies.description`,
				})}
				keywords={intl.formatMessage({
					id: 'component.seo.keywords',
				})}
				img={'/apple-touch-icon.png'}
				locale={locale}
				url={`${ENV_VARS.ALTREAL_DOMAIN}/case-studies/studies`}
			></CustomHeader>

			<div className={blogs.container}>
				<Nav></Nav>
				<div className={`${blogs.blog} container`}>
					<div className={blogs.grid}>
						{posts?.map((post: any, index: number) => {
							const postId = post.sys.id;
							const postSlug = getPostSlug(post);
							const postTitle = getPostTitle(post);
							const postCoverImgUrl = getPostImgUrls(post)[0];
							const postDate = getPostDate(post, locale);
							const tags = getPostTags(post);
							const summary = getPostSummary(post);

							return (
								<Link
									href={`/case-studies/study/${postSlug}`}
									key={postId}
									className={`${cards.estimatorCard} card p-0`}
									style={{ overflow: 'hidden' }}
								>
									<div>
										<Image
											className='position-relative'
											fill
											src={postCoverImgUrl}
											alt={postTitle}
											style={{
												objectFit: 'cover',
												aspectRatio: '16/9',
											}}
											placeholder='blur'
											blurDataURL='/images/loading-image-placeholder.svg'
										></Image>
									</div>
									<article className='pt-3 pb-3 ps-4 pe-4 d-flex flex-column justify-content-between h-100'>
										<section className='d-flex flex-column h-100'>
											{index === 0 ? (
												<h1 className={blogs.title}>{postTitle}</h1>
											) : (
												<h2 className={blogs.title}>{postTitle}</h2>
											)}
											<p className={`${blogs.summary}`}>{summary}</p>
										</section>
										<section className='h6 mt-3 row justify-content-between'>
											<div className='col-12 col-sm-6 w-auto align-self-end'>
												<div className='text-muted'>
													<div>{postDate}</div>
													<div>
														<TagsComponent tags={tags}></TagsComponent>
													</div>
												</div>
											</div>
											<div
												className='col d-flex align-self-end justify-content-end pt-3'
												style={{ whiteSpace: 'nowrap' }}
											>
												<FontAwesomeIcon
													icon={faLink}
													className='me-2'
												></FontAwesomeIcon>
												<FormattedMessage
													id='component.nav.read_more'
													defaultMessage='Read more'
												></FormattedMessage>
											</div>
										</section>
										<StudyStructuredData
											post={post}
											locale={locale}
										></StudyStructuredData>
									</article>
								</Link>
							);
						})}
					</div>
				</div>
				<Footer></Footer>
			</div>
		</div>
	);
}
