import React, { useRef } from 'react';
import components from '/styles/components.module.scss';

interface ICtaButton {
	heading?: string;
	label: string;
	gradient?: boolean;
	href?: string;
	handler?: (
		e:
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
			| React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) => void;
	disabled?: boolean;
	small?: boolean;
}

export default function CtaButton({
	heading,
	label,
	href,
	handler,
	disabled,
	small,
}: ICtaButton) {
	const btnRef = useRef(null);
	const elem = btnRef?.current as HTMLElement | null;

	// if (handler !== null && elem !== null) {
	// 	elem.addEventListener("click", handler);
	// }
	return (
		<div className={`${components.ctaButtonContainer}`}>
			{heading && <h1 className={components.ctaButtonHeading}>{heading}</h1>}
			<div className={`${components.ctaButton}`}>
				{handler !== undefined ? (
					<button
						ref={btnRef}
						onClick={(e) => handler(e)}
						className={`${components.btnPhoton} ${
							small === true ? components.small : ''
						} ${
							disabled === true ? 'disabled' : ''
						} ${'btn btn-primary btn-photon text-nowrap'}`}
					>
						{label}
					</button>
				) : (
					<a
						ref={btnRef}
						href={href}
						className={`${components.btnPhoton} ${
							small === true ? components.small : ''
						} ${
							disabled === true ? 'disabled' : ''
						} ${'btn btn-primary btn-photon text-nowrap'}`}
					>
						{label}
					</a>
				)}
			</div>
		</div>
	);
}
