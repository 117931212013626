import {
	Button,
	Heading,
	useAuthenticator,
	useTheme,
	View,
	Text,
	Link,
} from '@aws-amplify/ui-react';

import Image from 'next/image';
import { FormattedMessage } from 'react-intl';

import authentication from '/styles/ui/Authentication.module.scss';

export const authComponents = {
	Header() {
		const { tokens } = useTheme();

		return (
			<View textAlign='center' padding={tokens.space.large}>
				<Image
					alt='alt.real logo'
					className={authentication.authLogo}
					src='/logo/altreal-logo-desktop.png'
					fill
				/>
			</View>
		);
	},

	Footer() {
		const { tokens } = useTheme();

		return (
			<View textAlign='center' padding={tokens.space.large}>
				{/* <Text>&copy; All Rights Reserved</Text> */}

				<Text>
					<FormattedMessage
						id='component.configurator.click_to_accept'
						defaultMessage='I accept the terms and conditions'
					></FormattedMessage>{' '}
					<a
						className='link-primary'
						target='_blank'
						href='/terms-and-conditions'
					>
						<FormattedMessage
							id='component.configurator.terms_and_conditions'
							defaultMessage='terms and conditions'
						></FormattedMessage>
					</a>{' '}
					<FormattedMessage
						id='component.common.and'
						defaultMessage='and'
					></FormattedMessage>{' '}
					<a className='link-primary' target='_blank' href='/privacy-policy'>
						<FormattedMessage
							id='component.configurator.privacy_policy'
							defaultMessage='privacy policy'
						></FormattedMessage>
					</a>
				</Text>
				<Link
					className='amplify-button amplify-button--link amplify-button--small mt-3 mb-3'
					href='/'
				>
					<FormattedMessage id='component.nav.back_to_home'></FormattedMessage>
				</Link>
			</View>
		);
	},

	//SignIn: {
	// Header() {
	// 	const { tokens } = useTheme();

	// 	return (
	// 		<Heading
	// 			padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
	// 			level={3}
	// 		>
	// 			{/* Sign in to your account */}
	// 		</Heading>
	// 	);
	// },
	// Footer() {
	// 	const { toResetPassword } = useAuthenticator();

	// 	return (
	// 		<View textAlign="center">
	// 			<Button
	// 				fontWeight="normal"
	// 				onClick={toResetPassword}
	// 				size="small"
	// 				variation="link"
	// 			>
	// 				<Text>Reset Password</Text>
	// 			</Button>
	// 		</View>
	// 	);
	// },
	//},

	// SignUp: {
	// 	Header() {
	// 		const { tokens } = useTheme();

	// 		return (
	// 			<Heading
	// 				padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
	// 				level={3}
	// 			>
	// 				Create a new account
	// 			</Heading>
	// 		);
	// 	},
	// 	Footer() {
	// 		const { toSignIn } = useAuthenticator();

	// 		return (
	// 			<View textAlign="center">
	// 				<Button
	// 					fontWeight="normal"
	// 					onClick={toSignIn}
	// 					size="small"
	// 					variation="link"
	// 				>
	// 					Back to Sign In
	// 				</Button>
	// 			</View>
	// 		);
	// 	},
	// },
	// ConfirmSignUp: {
	// 	Header() {
	// 		const { tokens } = useTheme();
	// 		return (
	// 			<Heading
	// 				padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
	// 				level={3}
	// 			>
	// 				Enter Information:
	// 			</Heading>
	// 		);
	// 	},
	// 	Footer() {
	// 		return <div>Footer Information</div>;
	// 	},
	// },
	// SetupTOTP: {
	// 	Header() {
	// 		const { tokens } = useTheme();
	// 		return (
	// 			<Heading
	// 				padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
	// 				level={3}
	// 			>
	// 				Enter Information:
	// 			</Heading>
	// 		);
	// 	},
	// 	Footer() {
	// 		return <div>Footer Information</div>;
	// 	},
	// },
	// ConfirmSignIn: {
	// 	Header() {
	// 		const { tokens } = useTheme();
	// 		return (
	// 			<Heading
	// 				padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
	// 				level={3}
	// 			>
	// 				Enter Information:
	// 			</Heading>
	// 		);
	// 	},
	// 	Footer() {
	// 		return <div>Footer Information</div>;
	// 	},
	// },
	// ResetPassword: {
	// 	Header() {
	// 		const { tokens } = useTheme();
	// 		return (
	// 			<Heading
	// 				padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
	// 				level={3}
	// 			>
	// 				Enter Information:
	// 			</Heading>
	// 		);
	// 	},
	// 	Footer() {
	// 		return <div>Footer Information</div>;
	// 	},
	// },
	// ConfirmResetPassword: {
	// 	Header() {
	// 		const { tokens } = useTheme();
	// 		return (
	// 			<Heading
	// 				padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
	// 				level={3}
	// 			>
	// 				Enter Information:
	// 			</Heading>
	// 		);
	// 	},
	// 	Footer() {
	// 		return <div>Footer Information</div>;
	// 	},
	// },
};

export const getAuthFormTranslations = (locale: string) => {
	return {
		'ro-RO': {
			'Sign In': 'Intră în cont', // Tab header
			'Sign in': 'Intră în cont', // Button label
			'Sign in to your account': 'Welcome Back!',
			Username: 'Nume de utilizator', // Username label
			Email: 'Email', // Email label
			Password: 'Parola', // Password label
			'Forgot your password?': 'Ai uitat parola?',
			'Reset Password': 'Ai uitat parola?',
			'Create Account': 'Creează cont',
			'Enter your Email': 'Introdu adresa ta de Email',
			'Enter your email': 'Introdu adresa ta de Email',
			'Enter your password': 'Parola',
			'Enter your Password': 'Introdu parola',
			'Confirm Password': 'Confirmă parola',
			'Please confirm your Password': 'Confirmă noua parolă',
			'Given Name': 'Prenume',
			'Family Name': 'Nume de familie',
			'Enter your Given Name': 'Introdu prenumele tău',
			'Enter your Family Name': 'Introdu numele tău de familie',
			'Back to Sign In': 'Ai deja cont?',
			or: 'sau',
			'Sign In with Facebook': 'Loghează-te cu Facebook',
			'Sign Up with Facebook': 'Înregistrează-te cu Facebook',
			'Sign In with Google': 'Loghează-te cu Google',
			'Sign Up with Google': 'Înregistrează-te cu Google',
			'Send code': 'Trimite cod',
			'Account recovery requires verified contact information':
				'Recuperarea contului necesită verificarea informațiilor de contact',
			'Change Password': 'Schimbă parola',
			Changing: 'Schimbă',
			Code: 'Cod',
			'Code *': 'Cod',
			'Confirm Sign Up': 'Confirmă înregistrarea',
			'Confirm SMS Code': 'Confirmă codul primit prin SMS',
			'Confirm TOTP Code': 'Confirmă codul primit print OTP',
			Confirm: 'Confirmă',
			'Confirmation Code': 'Cod de confirmare',
			Confirming: 'În curs de confirmare',
			'Create a new account': 'Creează cont nou',
			'Creating Account': 'În curs de creare cont',
			'Dismiss alert': 'Ingoră alerta',
			'Enter your code': 'Intru codul',
			'Enter your Phone Number': 'Introdu numărul de telefon',
			'Enter your username': 'Introdu numele de utlizator',
			'Forgot password?': 'Ai uitat parola?',
			'Hide password': 'Ascunde parola',
			'It may take a minute to arrive': 'Livrarea ar putea dura cateva minute',
			Loading: 'Se încarcă',
			'New password': 'Parola nouă',
			'New Password': 'Parola nouă',
			'Phone Number': 'Număr de telefon',
			'Resend Code': 'Retrimite cod',
			'Reset your password': 'Reset your password',
			'Reset your Password': 'Resetează parola',
			'Send Code': 'Trimite cod',
			Sending: 'În curs de trimitere',
			'Setup TOTP': 'Setează OTP',
			'Show password': 'Arată parola',
			'Sign In with Amazon': 'Loghează-te cu Amazon',
			'Sign In with Apple': 'Loghează-te cu Apple',
			'Signing in': 'În curs de logare',
			Skip: 'Treci peste',
			Submit: 'Trimite',
			Submitting: 'În curs de trimitere',
			'Verify Contact': 'Verfică datele de contact',
			Verify: 'În curs de verificare',
			'We Emailed You': 'V-am scris pe email',
			'We Sent A Code': 'V-am trimis un cod',
			'We Texted You': 'V-am trimis un email',
			'Your code is on the way. To log in, enter the code we emailed to':
				'Codul a fost trimis. Pentru a intra în cont, introduceți codul primit prin email',
			'Your code is on the way. To log in, enter the code we sent you':
				'Codul a fost trimis. Pentru a intra în cont, introduceți codul primit',
			'Your code is on the way. To log in, enter the code we texted to':
				'Codul a fost trimis. Pentru a intra în cont, introduceți codul primit prin SMS',
			'Password must have special characters':
				'Parola trebuie să conțină caractere speciale',
			'Your passwords must match': 'Parolele trebuie să fie identice',
			'Password must have at least 8 characters':
				'Parola trebuie să conțină cel puțin 8 caractere',
			'Password must have upper case letters':
				'Parola trebuie să conțină cel puțin o majusculă',
			'Incorrect username or password.': 'Email sau parolă incorectă',
			'Temporary password has expired and must be reset by an administrator.':
				'Parola temporară a expirat și trebuie resetată de un administrator.',
			'User password cannot be reset in the current state.':
				'Parola nu poate fi resetată în starea actuală. Contactați-ne prin rubrica de suport.',
		},
		'en-GB': {
			'Account recovery requires verified contact information':
				'Account recovery requires verified contact information',
			'Back to Sign In': 'Back to Sign In',
			'Change Password': 'Change Password',
			Changing: 'Changing',
			Code: 'Code',
			'Confirm Password': 'Confirm Password',
			'Confirm Sign Up': 'Confirm Sign Up',
			'Confirm SMS Code': 'Confirm SMS Code',
			'Confirm TOTP Code': 'Confirm TOTP Code',
			Confirm: 'Confirm',
			'Confirmation Code': 'Confirmation Code',
			Confirming: 'Confirming',
			'Create a new account': 'Create a new account',
			'Create Account': 'Create Account',
			'Creating Account': 'Creating Account',
			'Dismiss alert': 'Dismiss alert',
			Email: 'Email',
			'Enter your code': 'Enter your code',
			'Enter your email': 'Enter your email',
			'Enter your Phone Number': 'Enter your phone number',
			'Enter your username': 'Enter your username',
			'Forgot password?': 'Forgot password?',
			'Forgot your password?': 'Forgot your password?',
			'Hide password': 'Hide password',
			'It may take a minute to arrive': 'It may take a minute to arrive',
			Loading: 'Loading',
			'New password': 'New password',
			or: 'or',
			Password: 'Password',
			'Phone Number': 'Phone Number',
			'Resend Code': 'Resend Code',
			'Reset your password': 'Reset your password',
			'Reset your Password': 'Reset your Password',
			'Send code': 'Send code',
			'Send Code': 'Send Code',
			Sending: 'Sending',
			'Setup TOTP': 'Setup TOTP',
			'Show password': 'Show password',
			'Sign in to your account': 'Sign in to your account',
			'Sign In with Amazon': 'Sign In with Amazon',
			'Sign In with Apple': 'Sign In with Apple',
			'Sign In with Facebook': 'Sign In with Facebook',
			'Sign In with Google': 'Sign In with Google',
			'Sign in': 'Sign in',
			'Sign In': 'Sign In',
			'Signing in': 'Signing in',
			Skip: 'Skip',
			Submit: 'Submit',
			Submitting: 'Submitting',
			Username: 'Username',
			'Verify Contact': 'Verify Contact',
			Verify: 'Verify',
			'We Emailed You': 'We Emailed You',
			'We Sent A Code': 'We Sent A Code',
			'We Texted You': 'We Texted You',
			'Your code is on the way. To log in, enter the code we emailed to':
				'Your code is on the way. To log in, enter the code we emailed to',
			'Your code is on the way. To log in, enter the code we sent you':
				'Your code is on the way. To log in, enter the code we sent you',
			'Your code is on the way. To log in, enter the code we texted to':
				'Your code is on the way. To log in, enter the code we texted to',
			'Password must have special characters':
				'Password must have special characters',
			'Your passwords must match': 'Your passwords must match',
			'Password must have at least 8 characters':
				'Password must have at least 8 characters',
			'Password must have upper case letters':
				'Password must have upper case letters',
			'Incorrect username or password.': 'Incorrect username or password.',
			'Temporary password has expired and must be reset by an administrator.':
				'Temporary password has expired and must be reset by an administrator.',
			'User password cannot be reset in the current state.':
				'User password cannot be reset in the current state.',
		},
	};
};

// export const getAuthFormFields = (intl) => {
// 	return {
// 		signIn: {
// 			username: {
// 				placeholder: "Enter your email",
// 			},
// 		},
// 		signUp: {
// 			password: {
// 				label: "Password:",
// 				placeholder: "Enter your Password:",
// 				isRequired: false,
// 				order: 2,
// 			},
// 			confirm_password: {
// 				label: "Confirm Password:",
// 				order: 1,
// 			},
// 		},
// 		forceNewPassword: {
// 			password: {
// 				placeholder: "Enter your Password:",
// 			},
// 		},
// 		resetPassword: {
// 			username: {
// 				placeholder: "Enter your email:",
// 			},
// 		},
// 		confirmResetPassword: {
// 			confirmation_code: {
// 				placeholder: "Enter your Confirmation Code:",
// 				label: "New Label",
// 				isRequired: false,
// 			},
// 			confirm_password: {
// 				placeholder: "Enter your Password Please:",
// 			},
// 		},
// 		setupTOTP: {
// 			QR: {
// 				totpIssuer: "test issuer",
// 				totpUsername: "amplify_qr_test_user",
// 			},
// 			confirmation_code: {
// 				label: "New Label",
// 				placeholder: "Enter your Confirmation Code:",
// 				isRequired: false,
// 			},
// 		},
// 		confirmSignIn: {
// 			confirmation_code: {
// 				label: "New Label",
// 				placeholder: "Enter your Confirmation Code:",
// 				isRequired: false,
// 			},
// 		},
// 	};
// };
