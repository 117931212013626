import React from 'react';
import Link from 'next/link';
import footers from 'styles/Footer.module.scss';
import { ENV_VARS } from 'helpers/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faInstagram,
	faLinkedinIn,
	faSquareFacebook,
} from '@fortawesome/free-brands-svg-icons';

export default function SocialMediaLinks() {
	return (
		<ul className={footers.socialMediaLinks}>
			<li>
				<Link
					href={ENV_VARS.ALTREAL_FACEBOOK}
					target='_blank'
					passHref
					id='altreal-facebook-link'
					title='Alt.Real Facebook'
				>
					<FontAwesomeIcon
						icon={faSquareFacebook}
						size='2x'
						className={footers.socialIcon}
					></FontAwesomeIcon>
				</Link>
			</li>
			<li>
				<Link
					href={ENV_VARS.ALTREAL_LINKEDIN}
					target='_blank'
					passHref
					id='altreal-linkedin-link'
					title='Alt.Real LinkedIn'
				>
					<FontAwesomeIcon
						icon={faLinkedinIn}
						size='2x'
						className={footers.socialIcon}
					></FontAwesomeIcon>
				</Link>
			</li>
			<li>
				<Link
					href={ENV_VARS.ALTREAL_INSTAGRAM}
					target='_blank'
					passHref
					id='altreal-instagram-link'
					title='Alt.Real Instagram'
				>
					<FontAwesomeIcon
						icon={faInstagram}
						size='2x'
						className={footers.socialIcon}
					></FontAwesomeIcon>
				</Link>
			</li>
		</ul>
	);
}
