import React, { useEffect } from "react";
import pluginConfig from "./CookieConsentConfig";
import "vanilla-cookieconsent";

const CookieConsentComponent = () => {
	useEffect(() => {
		/**
		 * useEffect is executed twice (React 18+),
		 * make sure the plugin is initialized and executed once
		 */
		if (!document.getElementById("cc--main")) {
			//@ts-ignore
			window.CookieConsentApi = window.initCookieConsent();
			//@ts-ignore
			window.CookieConsentApi.run(pluginConfig);
		}
	}, []);

	return null;
};

export default CookieConsentComponent;
